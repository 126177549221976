import React, { Component } from "react";
import ErrorBoundaryTemplate from "./errorBoundaryTemplate";
import { isUndefined } from "util";
import { ErrorLog } from "./logTheError";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    alert();
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    ErrorLog(error, info);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorBoundaryTemplate />;
    }
    if (!isUndefined(this.props.children)) return this.props.children;
    return <ErrorBoundaryTemplate error={this.props.error} />;
  }
}

export default ErrorBoundary;
