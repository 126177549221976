export const panelFunctions = {
    getMenu: () => {
        return [
            {
                id: "dashboard",
                content: "Dashboardd",
                accessibilityLabel: "Dashboard",
                link: "/panel/dashboard",
                panelID: "dashboard"
            },
            // {
            //     id: "account",
            //     content: "Account",
            //     accessibilityLabel: "Account",
            //     link: "panel/config?open=account",
            //     panelID: "account"
            // },
            // {
            //     id: "import",
            //     content: "Import/Upload",
            //     accessibilityLabel: "Import",
            //     link: "/panel/import",
            //     panelID: "import"
            // },
            // {
            //     id: "profiling",
            //     content: "Profile",
            //     accessibilityLabel: "Profile",
            //     link: "/panel/profiling",
            //     panelID: "profiling"
            // },
            {
                id: "products",
                content: "Products",
                accessibilityLabel: "Products",
                link: "/panel/products",
                panelID: "products"
            },
            {
                id: "order",
                content: "Orders",
                accessibilityLabel: "Orders",
                link: "/panel/order",
                panelID: "Orders"
            },
            // {
            //     id: "customers",
            //     content: "Customers",
            //     accessibilityLabel: "Customers",
            //     link: "/panel/customer",
            //     panelID: "Customers"
            // },
            {
                id: "configuration",
                content: "Configuration",
                accessibilityLabel: "Configuration",
                link: "/panel/config",
                panelID: "configuration"
            },
            // {
            //     id: "plans",
            //     content: "Plans",
            //     accessibilityLabel: "Plans",
            //     link: "/panel/plan",
            //     panelID: "plans"
            // },
            // {
            //     id: "queuedtasks",
            //     content: "Activities",
            //     accessibilityLabel: "Activities",
            //     link: "/panel/queuedtasks",
            //     panelID: "queuedtasks"
            // },
            {
                id: "help",
                content: "Help",
                accessibilityLabel: "Help",
                link: "/panel/help",
                panelID: "help"
            },
            {
                id:"Transaction",
                content:"Transactions",
                accessibilityLabel:"Transaction",
                link:"panel/transaction",
                panelId:"Transaction"
            },
            {
                // id:"Vendors",
                // content:"Vendors",
                // accessibilityLabel:"Transaction",
                // link:"panel/vendors",
                // panelId:"Vendors",
                
            }
        
        ];
    }
};
