import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Checkbox,
  RadioButton,
  Select,
  Tooltip,
  DataTable,
  Button,
  Label,
  ContextualSaveBar,
  Icon,
  ChoiceList,
  Popover,
  Thumbnail,
  Stack,
  Card,
  TextStyle,
  FormLayout,
  Banner,
  Collapsible,
} from "@shopify/polaris";
import { CircleInformationMajor, InfoMinor } from "@shopify/polaris-icons";
import Skeleton from "./skeleton";
import noProduct from "./noProduct.png";
// import noProduct from "./";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { environment } from "../environments/environment";
import {
  ViewMinor,
  HideMinor,
  EditMinor,
  CircleChevronDownMinor,
  CircleChevronUpMinor,
} from "@shopify/polaris-icons";

// import { type } from "jquery";
class Formbuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form,
      updated: false,
      sync: props.sync !== undefined ? props.sync : false,
      buttonLoading: false,
      files: [],
      faqShowHide: [true],
      tableData: [],
      tableDataMarket: [],
      tableDataSelect: [],
      feeSettingsModify: [],
      validateForm: {},
      closeEye: false,
      activepop: false,
      onChangeIndex: 0,
    };
  }

  render() {
    function getFieldOptions(field) {
      let allValue = [];
      if (typeof field.value === "string") {
        allValue = field.value.split(",")
      } else {
        allValue = field.value;
      }
      if (field?.options?.length === allValue?.length) {
        return [{ label: "All", value: "all" }]
      }

      const finder = allValue.find(val => val === "all")
      if (finder) {
        return [{ label: "All", value: "all" }]
      }
      return [{ label: "All", value: "all" }, ...field.options]
    }
    let url = environment.API_ENDPOINT;
    let newUrl = url.replace("/public/", "");



    return (
      <FormLayout>
        {this.state.form === undefined ? (
          <Skeleton case="bodyconfig" />
        ) : (
          this.state.form.map((field, index) => {
            if (this.props.title === "FAQs") {
              return (
                <div key={index}>
                  <Card.Section
                    // title={field.ques}
                    actions={[
                      {
                        content: "Edit",
                        onAction: () => {
                          this.props.faqModal("edit", field);
                        },
                      },
                      {
                        content: "Delete",
                        destructive: true,
                        onAction: () => {
                          this.props.faqModal("delete", field);
                        },
                      },
                      {
                        content: (
                          <Icon
                            source={
                              this.state.faqShowHide[index]
                                ? CircleChevronDownMinor
                                : CircleChevronUpMinor
                            }
                            backdrop={true}
                          />
                        ),
                        onAction: () => {
                          let { faqShowHide } = this.state;
                          faqShowHide[index] = !faqShowHide[index];
                          this.setState({
                            faqShowHide,
                          });
                        },
                      },
                    ]}
                  >
                    <TextStyle variation="strong">{field.ques.charAt(0).toUpperCase() + field.ques.slice(1)}</TextStyle>

                    {this.state.faqShowHide[index] && (
                      <div dangerouslySetInnerHTML={{ __html: field.ans }} />
                    )}
                  </Card.Section>
                </div>
              );
            } else if (field["Marketplace Fee"]) {
              return (
                <div key={index}>
                  <Stack spacing="loose" vertical="true">
                    <Stack spacing="tight" vertical="true">
                      <p>Marketplace Fee</p>
                      <DataTable
                        columnContentTypes={["select", "select", "text"]}
                        headings={[
                          "Marketplace",
                          "Fee Type",
                          "Fee Value",
                          "Action",
                        ]}
                        rows={this.props.rows}
                      />
                    </Stack>
                    <Stack alignment="center" distribution="trailing">
                      <Stack.Item>
                        <Button
                          primary
                          onClick={() => {
                            this.props.addMore();
                          }}
                        >
                          Add More
                        </Button>
                      </Stack.Item>
                    </Stack>
                  </Stack>
                </div>
              );
            } else {
              if (
                (this.state.disableMarketPlace || this.state.disableaReviews) &&
                field.code !==
                "/general/sellers_review_rating_setting/order_status" && field.code !==
                "/general/sellers_review_rating_setting/review_msg"

              ) {
                switch (field.type) {
                  case "select":
                    return (
                      <div key={index}>
                        <Select
                          label={
                            <Stack>
                              <span>{field.title}</span>
                              {field.mail_code && field.in_plan && (
                                <Tooltip
                                  content={`Click to edit ${field.title} template`}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.props.editMail(field.mail_code);
                                    }}
                                  >
                                    <Icon
                                      source={EditMinor}
                                      color="base"
                                    ></Icon>
                                  </span>
                                </Tooltip>
                              )}
                            </Stack>
                          }
                          options={field.options}
                          labelInline={false}
                          helpText={field.helpText}
                          placeholder={"Choose"}
                          disabled={
                            field.code ===
                            "/general/vendorEmail/supplier_account_confirmation_notication"
                          }
                          onChange={(val) => {
                            this.handleChange(index, val, field.code, "select");
                          }}
                          value={field.value}
                        />
                      </div>
                    );
                }
              } else {
                switch (field.type) {
                  case "text":
                    return (
                      <div key={index}>
                        {this.state.disableField &&
                          this.state.disableField.includes(field.title) &&
                          !this.state.disableTC ? null : (
                          <>
                            <TextField
                              label={
                                <Stack alignment="center" spacing="extraTight">
                                  <span>{field.title}</span>
                                  {/* <TextStyle variation="negative">
                                    {field.required ? "*" : ""}
                                  </TextStyle> */}
                                </Stack>
                              }
                              value={field.value}
                              autoComplete="new-password"
                              type={
                                field.title === "Paypal Application Secret" &&
                                  !this.state.closeEye
                                  ? "password"
                                  : "text"
                              }
                              error={
                                this.props.validateForm === "Payment" &&
                                  field.title !== "Commission value"
                                  ? this.state.validateForm[field.title]
                                    ? "Enter minimum 3 characters."
                                    : ""
                                  : field.title ===
                                    "Sender Email by which all emails send to supplier"
                                    ? this.state.validateForm[field.title]
                                      ? "Enter Valid Email"
                                      : ""
                                    : ""
                              }
                              suffix={
                                field.title === "Paypal Application Secret" ? (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        closeEye: !this.state.closeEye,
                                      });
                                    }}
                                  >
                                    <Icon
                                      color="base"
                                      source={
                                        this.state.closeEye
                                          ? ViewMinor
                                          : HideMinor
                                      }
                                    />
                                  </span>
                                ) : null
                              }
                              clearButton
                              onClearButtonClick={this.handleChange.bind(
                                this,
                                index,
                                ""
                              )}
                              helpText={field.helpText}
                              placeholder={field.help}
                              onChange={(val) => {
                                this.handleChange(
                                  index,
                                  field.title === "Paypal Application Secret" ||
                                    field.title === "Paypal Application Client ID"
                                    ? val.trim()
                                    : val,
                                  field.title
                                );
                              }}
                              readOnly={false}
                            />
                          </>
                        )}
                      </div>
                    );

                  case "non_editable":
                    return (
                      <div key={index}>
                        <Stack distribution="fillEvenly" alignment="center">
                          <b>{field.title} </b>
                          <span>{field.value || "Not Available"}</span>
                        </Stack>
                      </div>
                    );

                  case "multiselect":
                    return (
                      <div key={index}>
                        <div class="Polaris-Labelled__LabelWrapper">
                          <div class="Polaris-Label">
                            <label
                              id="PolarisSelect2Label"
                              for="PolarisSelect2"
                              class="Polaris-Label__Text"
                            >
                              <div class="Polaris-Stack Polaris-Stack--spacingTight Polaris-Stack--alignmentCenter">
                                <div class="Polaris-Stack__Item">
                                  <span>{field.title}</span>
                                </div>
                                {
                                  (field.title === "Allowed Collections" || field.title === "Allowed Categories") &&
                                  <div class="Polaris-Stack__Item">
                                    <Tooltip content="You can't disallow categories that have been assigned to live products." width="wide">
                                      <Icon
                                        source={CircleInformationMajor}
                                        color="success"
                                      ></Icon>
                                    </Tooltip>
                                  </div>
                                }
                              </div>
                            </label>
                          </div>
                        </div>

                        <Popover
                          fullWidth={true}
                          active={this.state.activepop}
                          activator={
                            <Button
                              fullWidth={true}
                              disclosure="down"
                              disabled={!field.options.length > 0}
                              onClick={() => {
                                this.setState({
                                  activepop: !this.state.activepop,
                                });
                              }}
                            >
                              {field.title === "Allowed Collections" &&
                                !field.options.length > 0
                                ? "No Allowed Collection available"
                                : field.title}
                            </Button>
                          }
                          onClose={() => {
                            this.setState({ activepop: false });
                          }}
                        >

                          <div className="w-auto p-3">
                            <ChoiceList
                              allowMultiple
                              fullWidth={true}
                              title={field.title}

                              titleHidden
                              choices={getFieldOptions(field)}
                              selected={
                                typeof field.value === "string" ? field.value.split(",").length === field.options.length
                                  ? ["all"] : field.value
                                    .split(",")
                                    .filter((v) => v !== "")
                                  : field.value
                              }
                              onChange={this.handleChange.bind(this, index)}
                            />
                          </div>
                        </Popover>
                        <span className='Polaris-Labelled__HelpText mt_0'>Select categories in which the suppliers will be allowed to sell.</span>
                      </div>
                    );

                  case "codemirror":
                    return (
                      <div key={index}>
                        <Stack spacing="tight" vertical={true}>
                          <TextStyle>{field.title}</TextStyle>
                          <>
                            <CodeMirror
                              value={
                                field.value !== ""
                                  ? field.value
                                  : "// Write your header code here"
                              }
                              width="100%"
                              // readOnly
                              // aria-disabled={true}
                              maxHeight="50%"
                              helpText={field.helpText}
                              theme="dark"
                              extensions={[javascript({ jsx: true })]}
                              onChange={this.handleChange.bind(this, index)}
                            />
                          </>
                        </Stack>
                      </div>
                    );
                  case "textarea":
                    return (
                      <div key={index}>
                        <TextField
                          label={field.title}
                          value={field.value}
                          spellCheck={false}
                          // clearButton
                          // onClearButtonClick={this.handleChange.bind(
                          //   this,
                          //   index,
                          //   ""
                          // )}
                          multiline={4}
                          helpText={field.helpText}
                          placeholder={field.help}
                          onChange={this.handleChange.bind(this, index)}
                          readOnly={false}
                        />
                      </div>
                    );

                  case "image":

                    return (
                      <div key={index}>
                        <p>{field.title}</p>
                        <div className="p-3">
                          <Stack distribution="fillEvenly" alignment="center">
                            <Thumbnail
                              source={newUrl + field.value || noProduct}
                              alt={field.title}
                              size="medium"
                            />
                            <input
                              type="file"
                              onChange={(event) => {
                                this.setState({ onChangeIndex: index });
                                this.props.onChangeImageIndex(index)
                                this.props.onChooseImage(event);
                              }}
                              multiple
                              name="file"
                            />

                            <Button
                              loading={this.props.loadingButton[index]}
                              size="slim"
                              submit
                              onClick={() =>
                                this.props.upload(field.code, index)
                              }
                            >
                              Submit
                            </Button>
                            <Button
                              loading={this.props.loadingDeleteButton[index]}
                              size="slim"
                              destructive
                              onClick={() =>
                                this.props.deleteImage(
                                  field.code,
                                  index,
                                  "formbuilder"
                                )
                              }
                            >
                              Delete
                            </Button>
                          </Stack>
                        </div>
                      </div>
                    );

                  case "number":
                    return (
                      <div key={index}>
                        {this.state.disableField &&
                          this.state.disableField.includes(field.title) &&
                          !this.state.disableTC ? null : (
                          <>
                            <TextField
                              label={
                                <Stack alignment="center" spacing="extraLoose">
                                  <span>{field.title}</span>
                                  {field.title === "Markup Price value" &&
                                    this.props.showSyncProducts && (
                                      <Button
                                        loading={this.props.syncLoader}
                                        size="slim"
                                        primary
                                        onClick={() => {
                                          this.props.syncProducts();
                                        }}
                                      >
                                        Sync Products
                                      </Button>
                                    )}
                                </Stack>
                              }
                              value={field.value}
                              type={"text"}
                              clearButton
                              onClearButtonClick={this.handleChange.bind(
                                this,
                                index,
                                ""
                              )}
                              helpText={field.helpText}
                              placeholder={field.help}
                              onChange={(val) => {
                                if (
                                  (val === "" ||
                                    /^\s*(?=.*[1-9])\d*(?:\.\d{0,2})?\s*$/.test(
                                      val
                                    )) &&
                                  (field.code ===
                                    "/general/commissionSetting/commission_value" ||
                                    field.code ===
                                    "/general/markupPriceSetting/markup_price_value" ||
                                    field.code ===
                                    "/general/vendorTransactionSettings/service_tax_value" ||
                                    field.code ===
                                    "/general/vendorOrderSettings/delayed_fulfillment_fine")
                                ) {
                                  this.handleChange(index, val, field.title);
                                } else if (
                                  /^[-+]?[0-9]+$/.test(val) ||
                                  val === ""
                                ) {
                                  this.handleChange(index, val, field.title);
                                }
                              }}
                              readOnly={false}
                            />
                          </>
                        )}
                      </div>
                    );
                  case "select":
                    return (
                      <div key={index}>
                        {!this.state.disableaReviews && (
                          <Select
                            label={
                              <Stack>
                                <span>{field.title}</span>
                                {field.mail_code && field.in_plan && (
                                  <Tooltip
                                    content={`Click to edit ${field.title} template`}
                                  >
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.props.editMail(field.mail_code);
                                      }}
                                    >
                                      <Icon
                                        source={EditMinor}
                                        color="base"
                                      ></Icon>
                                    </span>
                                  </Tooltip>
                                )}
                              </Stack>
                            }
                            options={field.options}
                            labelInline={false}
                            helpText={field.helpText}
                            placeholder={"Choose"}
                            disabled={
                              field.code ===
                              "/general/vendorEmail/supplier_account_confirmation_notication"
                            }
                            onChange={(val) => {
                              this.handleChange(
                                index,
                                val,
                                field.code ===
                                  "/general/vendorSettings/vendor_listing" ||
                                  field.code ===
                                  "/general/sellers_review_rating_setting/seller_review_rating"
                                  ? field.code
                                  : field.title,
                                "select"
                              );
                            }}
                            value={field.value}
                          />
                        )}
                        {
                          <Collapsible
                            open={
                              field.value === "yes" &&
                              (field.code ===
                                "/general/site_setting/vendor_name_on_prod_page" ||
                                field.code ===
                                "/general/site_setting/vendor_name_on_cart_page" ||
                                field.code ===
                                "/general/site_setting/vendor_name_on_order_page")
                            }
                          >
                            <div className="pt-2">
                              <Stack vertical>
                                <Banner status="info">
                                  Add this code snippet into
                                  {field.code ===
                                    "/general/site_setting/vendor_name_on_prod_page"
                                    ? " product"
                                    : field.code ===
                                      "/general/site_setting/vendor_name_on_cart_page"
                                      ? " cart"
                                      : " order"}{" "}
                                  liquid file to show the related supplier name.
                                  You have to follow these simple steps.
                                  <br />
                                  1. Login to your shopify store .
                                  <br />
                                  2. Click on online store .
                                  <br />
                                  3. Click on themes option.
                                  <br />
                                  4. Click on customise theme.
                                  <br />
                                  5. Now you will get option to edit code .
                                  Click on edit code option. All the files get
                                  visible . Search for the
                                  {field.code ===
                                    "/general/site_setting/vendor_name_on_prod_page"
                                    ? " product"
                                    : field.code ===
                                      "/general/site_setting/vendor_name_on_cart_page"
                                      ? " cart"
                                      : " order"}{" "}
                                  file through search bar and paste this code
                                  snippet as given below at the right place.
                                </Banner>
                                <CodeMirror
                                  value={
                                    field.code ===
                                      "/general/site_setting/vendor_name_on_prod_page"
                                      ? ' <div id="supplier_name"></div>\n' +
                                      " <script>\n" +
                                      ' var id = "{{product.tags}}".match(/seller_id=([0-9]{0,})/g)[0].replace("seller_id" , "user_id");\n' +
                                      "fetch(`${environment.API_ENDPOINT}connector/vendor/getvendorByUserId?${id}`, {\n" +
                                      " method: 'GET', \n" +
                                      "       })\n" +
                                      " .then((response) => response.json())\n" +
                                      " .then((data) => {\n" +
                                      ' document.getElementById("supplier_name").innerHTML = `Seller Name: ${data.data[0].shops[0].name}`;\n' +
                                      "   })\n" +
                                      " .catch((error) => {\n" +
                                      '   console.error("Error:", error);\n' +
                                      "  });\n" +
                                      "</script>"
                                      : field.code ===
                                        "/general/site_setting/vendor_name_on_cart_page"
                                        ? "<div id={{ forloop.index }}></div>\n" +
                                        "<script>\n" +
                                        'fetch(`${environment.API_ENDPOINT}connector/vendor/getvendorByUserId?${"{{ item.product.tags }}".match(/seller_id=([0-9]{0,})/g)[0].replace("seller_id" , "user_id")}`, {\n' +
                                        ' method: "GET", \n' +
                                        "})\n" +
                                        ".then((response) => response.json())\n" +
                                        ".then((data) => {\n" +
                                        " document.getElementById({{forloop.index}}).innerHTML = `Seller Name: ${data.data[0].shops[0].name}`;\n" +
                                        "})\n" +
                                        ".catch((error) => {\n" +
                                        'console.error("Error:", error);\n' +
                                        "});\n" +
                                        "</script>"
                                        : "<div id={{ forloop.index }}></div>\n" +
                                        "<script>   \n" +
                                        'fetch(`${environment.API_ENDPOINT}connector/vendor/getvendorByUserId?${"{{ line_item.product.tags }}".match(/seller_id=([0-9]{0,})/g)[0].replace("seller_id" , "user_id")}`, {\n' +
                                        ' method: "GET", \n' +
                                        "})\n" +
                                        ".then((response) => response.json())\n" +
                                        ".then((data) => {\n" +
                                        "document.getElementById({{forloop.index}}).innerHTML = `Seller Name: ${data.data[0].shops[0].name}`;\n" +
                                        "})\n" +
                                        ".catch((error) => {\n" +
                                        'console.error("Error:", error);\n' +
                                        "});\n" +
                                        "</script>"
                                  }
                                  width="100%"
                                  readOnly
                                  maxHeight="50%"
                                  theme="dark"
                                  helpText={field.helpText}
                                  extensions={[javascript({ jsx: true })]}
                                />
                              </Stack>
                            </div>
                          </Collapsible>
                        }
                      </div>
                    );

                  case "checkbox":
                    return (
                      <div key={index}>
                        {!field.parent_id}
                        {!field.parent_id && <Label>{field.title}</Label>}
                        <div className="row p-4">
                          {field.options.map((option) => {
                            return (
                              <div
                                className="col-sm-6 col-12"
                                key={field.options.indexOf(option)}
                              >
                                <Checkbox
                                  helpText={field.helpText}
                                  checked={
                                    field.value &&
                                    field.value.indexOf(option.value) !== -1
                                  }
                                  label={option.label}
                                  disabled={this.manageChild(field)}
                                  onChange={this.handleOptionsChange.bind(
                                    this,
                                    index,
                                    field.options.indexOf(option)
                                  )}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  case "radio":
                    return (
                      <div key={index}>
                        <Label>{field.title}</Label>
                        <div className="row">
                          {field.options.map((option) => {
                            return (
                              <div
                                className="col-md-4 col-sm-6 col-12"
                                key={field.options.indexOf(option)}
                              >
                                <RadioButton
                                  label={option.label}
                                  helpText={field.helpText}
                                  checked={option.value === field.value}
                                  id={option.key}
                                  name={option.key}
                                  onChange={this.handleOptionsChange.bind(
                                    this,
                                    index,
                                    field.options.indexOf(option)
                                  )}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                }
              }
            }
          })
        )}

        <div className="col-12 text-right mt-3">
          {/* <Button
            onClick={() => {
              this.setState({ buttonLoading: true });
              setTimeout(() => {
                this.setState({ buttonLoading: false });
              }, 7000);
              this.submitForm();
            }}
            loading={this.state.buttonLoading || this.state.form.length === 0}
            disabled={!this.state.updated}
            primary
          >
            Save
          </Button> */}

          {(this.state.updated || this.props.updatedFee) && (
            <ContextualSaveBar
              message="Unsaved changes"
              discardAction={
                this.props.refresh && {
                  loading: this.state.buttonLoading,
                  content: "Cancel",
                  onAction: () => {
                    this.setState({ buttonLoading: true });
                    setTimeout(() => {
                      this.setState({ buttonLoading: false });
                    }, 7000);
                    this.props.updatedFee && this.props.cancelUpdatedFee();
                    this.setState({ updated: false });
                    // this.props.refresh();
                  },
                }
              }
              saveAction={{
                loading: this.state.buttonLoading,
                content: "Save",
                onAction: () => {
                  this.setState({ buttonLoading: true });
                  setTimeout(() => {
                    this.setState({ buttonLoading: false });
                  }, 7000);
                  this.submitForm();
                  this.props.updatedFee && this.props.saveFee();
                },
              }}
            />
          )}
        </div>
      </FormLayout>
    );
  }

  manageChild(child) {
    let { form } = this.state;
    let submitObj = {};
    Object.keys(form).forEach((e) => {
      submitObj[form[e]["code"]] = form[e]["value"];
    });
    if (child["parent_id"] === undefined) return false;
    return submitObj[child["parent_id"]] === child["disabled_on"];
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    let disableTC = false;
    let disableMarketPlace = false;
    let disableaReviews = false;
    nextProps.form &&
      nextProps.form.map((field) => {
        if (
          (field.title === "Require terms & conditions" ||
            field.title === "Apply Fee for Delayed Fulfilment" ||
            field.title === "Commission Type" ||
            field.title === "Service Tax Type" ||
            field.title === "Enable Supplier Membership") &&
          (field.value == "yes" ||
            field.value == "percentage" ||
            field.value === "fixed")
        ) {
          disableTC = true;
        }
        if (
          field.code === "/general/vendorSettings/vendor_listing" &&
          field.value === "no"
        ) {
          disableMarketPlace = true;
        }
        if (
          field.code ===
          "/general/sellers_review_rating_setting/seller_review_rating" &&
          field.value === "no"
        ) {
          disableaReviews = true;
        }
      });
    this.setState({
      form: nextProps.form,
      buttonLoading: nextProps.buttonLoading,
      disableTC: disableTC,
      disableMarketPlace: disableMarketPlace,
      disableaReviews: disableaReviews,
      disableField: nextProps.disableField,
    });
  }

  submitForm() {
    let { form } = this.state;
    let submitObj = {};
    Object.keys(form).forEach((e) => {
      submitObj[form[e]["code"]] = form[e]["value"];
    });
    this.setState({ updated: false });

    var exists = Object.keys(this.state.validateForm).some((k) => {
      return this.state.validateForm[k] === true;
    });
    this.props.validateForm
      ? this.props.onSubmit(submitObj, exists)
      : this.props.onSubmit(submitObj, false);
  }
  handleChange(fieldIndex, value, title, select) {
    let optionValue = value;
    let finder
    this.setState((state) => {
      if (typeof value != "string") {
        state.form[fieldIndex].value = value;
        finder = value?.find(val => val === "all")
      }

      if (finder) {
        optionValue = "all"
      }
      state.updated = true;
      state.form[fieldIndex].value = optionValue;
      if (
        select &&
        (title === "Require terms & conditions" ||
          title === "Apply Fee for Delayed Fulfilment" ||
          title === "Commission Type" ||
          title === "Service Tax Type" ||
          title === "Enable Supplier Membership")
      ) {
        state.disableTC =
          (title === "Require terms & conditions" ||
            title === "Apply Fee for Delayed Fulfilment" ||
            title === "Commission Type" ||
            title === "Service Tax Type" ||
            title === "Enable Supplier Membership") &&
            (value === "yes" || value === "fixed" || value === "percentage")
            ? true
            : false;
      } else if (select && title === "/general/vendorSettings/vendor_listing") {
        state.disableMarketPlace = value === "no" ? true : false;
      } else if (
        select &&
        title === "/general/sellers_review_rating_setting/seller_review_rating"
      ) {
        state.disableaReviews = value === "no" ? true : false;
      }
      if (
        this.props.validateForm === "Payment" &&
        title !== "Commission value" &&
        title !== "Payment cycle (in days)" &&
        title !== "Bank Transfer Active"
      ) {
        state.validateForm[title] = !/^.{3,1000}$/.test(value);
      }
      if (title === "Sender Email by which all emails send to supplier") {
        state.validateForm[
          title
        ] = !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
          value
        );
      }
      return state;
    });
  }

  handleOptionsChange(fieldIndex, optionIndex, value) {
    const keyValue = this.state.form[fieldIndex].options[optionIndex].value;

    this.state.updated = true;
    switch (this.state.form[fieldIndex].type) {
      case "checkbox":
        if (this.state.form[fieldIndex].value == null)
          this.state.form[fieldIndex].value = [];
        const keyIndex = this.state.form[fieldIndex].value.indexOf(keyValue);
        if (value) {
          if (keyIndex === -1) {
            this.state.form[fieldIndex].value.push(keyValue);
          }
        } else {
          if (keyIndex !== -1) {
            this.state.form[fieldIndex].value.splice(keyIndex, 1);
          }
        }
        break;
      case "radio":
        this.state.form[fieldIndex].value = keyValue;
        break;
    }
    const state = this.state;
    this.setState(state);
  }
}

Formbuilder.propTypes = {
  form: PropTypes.array.isRequired,
  buttonLoading: PropTypes.bool,
  refresh: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  sync: PropTypes.bool,
};

export default Formbuilder;