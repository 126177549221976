import React from "react";
import { Redirect } from "react-router-dom";

import { Card, Tabs } from "@shopify/polaris";
import Base from "./base";
import { panelFunctions } from "./menu";
import { connect } from "react-redux";

class Header extends Base {
  constructor(props) {
    super(props);
    this.state = {
      appName: this.environment.AppName,
      loggedIn: true,
      menu: panelFunctions.getMenu(),
      selected: -1,
    };
  }

  componentDidMount() {
    this.setActiveTab(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    let data = setInterval(() => {
      this.setActiveTab(this.props);
    });
    setTimeout(() => {
      clearInterval(data);
    }, 3000);
    this.setActiveTab(nextProps);
  }

  setActiveTab(props) {
    const activeUrl = props.history.location.pathname;
    if (activeUrl.indexOf("/auth") === -1) {
      for (let i = 0; i < this.state.menu.length; i++) {
        if (activeUrl.indexOf(this.state.menu[i].link) !== -1) {
          this.state.selected = i;
        }
      }
      const state = this.state;
      this.setState(state);
    } else {
      setTimeout(() => {
        this.setActiveTab(props);
      }, 250);
    }
  }

  render() {
    if (!this.state.loggedIn) {
      return <Redirect to="/auth/login" />;
    } else {
      return (
        <Card>
          <Tabs
            tabs={this.state.menu}
            fitted={true}
            selected={this.state.selected}
            onSelect={this.handleTabChange.bind(this)}
          />
        </Card>
      );
    }
  }

  handleTabChange(event) {
    this.redirect(this.state.menu[event].link);
    this.state.selected = event;
    const state = this.state;
    this.setState(state);
  }

  logout() {
    this.globalState.removeLocalStorage("admin_authenticated");
    this.globalState.removeLocalStorage("auth_token");
    let state = Object.assign({}, this.state);
    state.loggedIn = false;
    this.notify("Logged out successfully");
    this.setState(state);
  }

  redirect(url) {
    this.props.history.push(url);
  }
}

export default connect()(Header);
