export const utility = (state = {}, action) => {
    if ( state['showToast'] === undefined ) {
        state['showToast'] = {};
        state['showID'] = 1;
    }
    switch ( action.type ) {
        case 'showToast' :
            state['showToast'][state['showID']] = action.state;
            state['showID']++;
            return {...state};
        case 'hideToast' :
            delete state['showToast'][action.state.id];
            return {...state};
        default : return state;
    }
};