export const environment = {
  production: true,
  isLive: false,
  skip_validation: false,
  file_upload: false,
  planDisable: true,
  API_ENDPOINT: "https://devbackend.cedcommerce.com/home/public/",
  BASE_ENDPOINT: "https://devbackend.cedcommerce.com/",
  SUPPLIER_ENDPOINT:"https://devsupplier.cedcommerce.com/",
  DOCUMENT_ENDPOINT:"https://staging.entredge.com/documentation/entredge-multivendor-marketplace-app-doc-product/",
  AppName: "Multivendor Marketplace Solution",
  helpFBPdfLink:
    "https://docs.cedcommerce.com/wp-content/uploads/2020/03/FB-Solution_User-Manual-Doc.pdf",
  createBusinessManager: "https://business.facebook.com/overview",
  appURL: "",
  APP_API_KEY: "8e58c700f1ecc2539682f6a04a8852c7",
  Bearer:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMiIsInJvbGUiOiJhcHAiLCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJhdWQiOiIxMjcuMC4wLjEiLCJ0b2tlbl9pZCI6MTczNDAwNDkzOX0.SLZRbYDAyWMskZxQ4fpWfkgRRM9zldGTFpCQt1103Z5rkr1qSGxoAAnAjhFig_2xHxdZPD2Z4OfSPDHh3QL-Di-Rr0WIArBNLUGJ9TCYd99ZRkX4KKMRuKsiS4dEKFE9qcbqwiTYEVXbMxfdKVlyGML-gOk2OTNollKMXscRt5QopS_k0-X0GFnXDeg-2AErdP-YCpyfKApYr9Zsoap8GZCVEOWWOjtSbY503ET4GBi-T7SgJhLwGYP6-ZTX7GPtVtGFttgB4T6gPSjMZTZ3Z5beu0CZCqzrNiZ2dMLpnISgq9w3nzR4sNgmyDGwW6YyzW35t1tRK9rDy20G5f3Rww",
};
/* Bearer on the last line is App token for server */
