export const necessaryInfo = (state = {}, action) => {
    switch ( action.type ) {
        case 'syncNecessaryInfo' : return {
            ...state,
            ...action.state
        };
        case 'syncPageInfo' : return {
            ...state,
            ...action.state
        };
        case 'theme' : return {
            ...state,
            theme: action.state
        };
        default :
            let theme = localStorage.getItem('cedTheme');
            let catalog_sync_only = localStorage.getItem('catalog_sync_only');
            return {
                ...state,
                theme : {
                    type : theme ? theme : 'old',
                    newDesign : theme && theme !== 'old'
                },
                catalog_sync_only: catalog_sync_only ? "no" : catalog_sync_only
        };
    }
};