import React, { Component } from "react";
import { Toast } from "@shopify/polaris";

import { requests, globalState } from "../services";
import { environment } from "../environments/environment";
import { isUndefined } from "util";

class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      error: false,
      message: "Success",
    };
  }
  // can we access bby extended class using this.{variable name}
  globalState = globalState;
  requests = requests;
  environment = environment;

  renderToast = () => {
    const { showToast, message, error, callBackFunction } = this.state;
    return showToast ? (
      <Toast
        content={message}
        error={error}
        action={
          callBackFunction
            ? {
                content: "Undo",
                onAction: () => {
                  callBackFunction();
                },
              }
            : null
        }
        onDismiss={this.notify}
      />
    ) : null;
  };
  // to call this render in extended class use super.render()
  render() {
    return <React.Fragment>{this.renderToast()}</React.Fragment>;
  }

  notify = (
    message = "Successfully Completed",
    error = false,
    callBackFunction = undefined
  ) => {
    if (error && message === "Successfully Completed") {
      message = "Something went wrong.";
    }

    if (this.props.dispatch !== undefined) {
      this.props.dispatch({
        type: "showToast",
        state: {
          error: error,
          message: message,
        },
      });
    } else {
      this.setState(({ showToast }) => ({
        showToast: !showToast,
        error: error,
        message: message,
        callBackFunction: callBackFunction,
      }));
    }
  };

  success = (
    message = "Successfully Completed",
    callBackFunction = undefined
  ) => {
    if (this.props.dispatch !== undefined) {
      this.props.dispatch({
        type: "showToast",
        state: {
          error: false,
          message: message,
        },
      });
    } else {
      this.setState(({ showToast }) => ({
        showToast: !showToast,
        error: false,
        message: message,
        callBackFunction: callBackFunction,
      }));
    }
  };

  error = (message = "Something went wrong", callBackFunction = undefined) => {
    if (this.props.dispatch !== undefined) {
      this.props.dispatch({
        type: "showToast",
        state: {
          error: true,
          message: message,
        },
      });
    } else {
      this.setState(({ showToast }) => ({
        showToast: !showToast,
        error: true,
        message: message,
        callBackFunction: callBackFunction,
      }));
    }
  };

  modifyConfigData(data, configKey) {
    let state = {};
    for (let i = 0; i < data.length; i++) {
      if (typeof state[configKey] !== "object") state[configKey] = {};
      state[configKey][data[i].code] = data[i].value;
      if (!isUndefined(data[i].options)) {
        data[i].options = this.modifyOptionsData(data[i].options, "option");
      }
      if (!isUndefined(data[i].available_marketplaces)) {
        data[i].available_marketplaces = this.modifyAvailableOptionsData(
          data[i].available_marketplaces,
          "availble_option"
        );
      }
    }
    return {
      data: data,
      state: state,
    };
  }

  modifyOptionsData(data) {
    let options = [];
    if (data !== null) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        let key = Object.keys(data)[i];
        options.push({
          label: data[key],
          value: key,
        });
      }
    }
    return options;
  }

  modifyAvailableOptionsData(data) {
    let available_marketplaces = [];
    if (data !== null) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        let key = Object.keys(data)[i];
        available_marketplaces.push({
          label: data[key],
          value: key,
        });
      }
    }
    return available_marketplaces;
  }
}

export default Base;
