import { isUndefined } from 'util';

export function ErrorLog(error, errorInfo) {
    if ( !isUndefined(error) && !isUndefined(errorInfo) ) {
        console.info(error, errorInfo);
    } else if ( !isUndefined(error) ) {
        console.log(error);
    } else if ( !isUndefined(errorInfo) ) {
        console.log(errorInfo);
    }
}