import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.slim.min";
import "popper.js/dist/umd/popper.min";
import "bootstrap/dist/js/bootstrap.min";
// import "@shopify/polaris/styles.css";
// import "@shopify/polaris/dist/styles.css";

import "@shopify/polaris/build/esm/styles.css";

import { Provider } from "react-redux";
import { store } from "./Reducers";

const StoreDispatcher = React.createContext(store.dispatch);
ReactDOM.render(
  <BrowserRouter basename="/admin/">
    <Provider store={store}>
      <StoreDispatcher.Provider value={store.dispatch}>
        <App />
      </StoreDispatcher.Provider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
