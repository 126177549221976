import React, {Component} from 'react';
import {ErrorLog} from "./logTheError";

class ErrorBoundaryTemplate extends Component {
    constructor(props) {
        super(props);
        ErrorLog(props.error);
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-center">
                        <br/>
                        <p style={{fontSize:'30px', color:'#b9b9b9'}}>
                            We Are Resolving your issue.
                        </p>
                        <br/>
                        <img style={{width:"50%"}} src={require('../../assets/img/fixing.svg')}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorBoundaryTemplate;