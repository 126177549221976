import React from 'react';
import * as queryString from "query-string";
import {
    Banner, Button, Card, Label, Layout, Page, Select, TextStyle, TextField, Checkbox
} from "@shopify/polaris";

import {Base} from "../../core";
import {isUndefined} from "util";

class Form extends Base {

    categoryList = [];

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            category: '',
            facebook_id: '',
            shop_url: '',
            installation_url: '',
            phone: '',
            message: 'Your Store is under review.',
            profile_default_category: '',
            modal: false,
            adWordStatus: "no",
            products_select: {
                query: "",
                targetCategory: "",
                warehouse: [],
            },
            google_configuration: {},
            hidePage: false//localStorage.getItem('ced_facebook_status')
        };
        this.getProductCategories();
    }

    componentWillMount() {
        const params = queryString.parse(this.props.location.search);

        if (!isUndefined(params.shop_url)) {
            this.setState({
                shop_url: params.shop_url,
                modal: true,
            });
        } else if (!params.install_form && params.message) {
            this.setState({message: params.message, hidePage: true});
        } else if (!params.install_form) {
            this.setState({hidePage: true});
        }
    }

    onChange = (key, value) => {
        this.setState({[key]: value});
    };

    handleSubmit = () => {
        let state = {
            sAppId: '1',
            name: this.state.name,
            email: this.state.email,
            category: this.state.products_select.targetCategory,
            facebook_id: this.state.facebook_id,
            shop_url: this.state.shop_url,
        };
        let flag = true;
        Object.keys(state).forEach(e => {
            if (state[e] === '' && e !== 'category') flag = false;
        });
        if (flag) {
            this.requests.getRequest('frontend/app/saveSellerInfo', state).then(e => {
                if (e.success) {
                    this.setState({hidePage: true});
                    localStorage.setItem('ced_facebook_status', 'pending');
                } else {
                    this.error(e.message);
                }
            })
        } else {
            this.notify('Please fill all the fields.', true);
        }
    };

    render() {
        let {hidePage} = this.state;
        {
            return hidePage ? <Page title="Status">
                    {super.render()}
                    <Card sectioned>
                        <Banner status="info">
                            <TextStyle>
                                {this.state.message}
                            </TextStyle>
                        </Banner>
                    </Card>
                </Page> :
                <Page title="Facebook marketplace approval form">
                    {super.render()}
                    {this.renderForm()}
                </Page>
        }

    }


    renderForm = () => {
        let {name, email, facebook_id, phone} = this.state;
        return (
            <Card sectioned>
                <br/>
                <div className="row">
                    <div className="col-sm-7 col-12">
                        <Label id={123}>Using Facebook advertising : </Label>
                        <Checkbox label="YES" checked={this.state.adWordStatus === "yes"}
                                  onChange={this.handleCheckBoxChange.bind(this, "yes")}/>
                        <br/>
                        <Checkbox label="No" checked={this.state.adWordStatus === "no"}
                                  onChange={this.handleCheckBoxChange.bind(this, "no")}/>
                    </div>
                    <div className=" col-12 col-sm-5 pt-5">
                        <img height="50px" src={require('../../assets/img/facebook.png')}/>
                    </div>
                </div>
                <br/>
                <br/>
                <TextField label={"Name"} value={name} onChange={this.onChange.bind(this, 'name')}/>
                <br/>
                <TextField label={"Email"} type={"email"} value={email} onChange={this.onChange.bind(this, 'email')}/>
                <br/>
                <TextField label={"Phone Number"} value={phone} onChange={this.onChange.bind(this, 'phone')}/>
                <br/>
                <TextField
                    label={"Facebook ID / Facebook Profile URL"}
                    value={facebook_id}
                    onChange={this.onChange.bind(this, 'facebook_id')}
                    helpText={<p> You can copy your profile URL from <a target={"_blank"}
                                                                        href={"https://facebook.com/me"}>here</a></p>}
                />
                <br/>
                {this.renderCategoryTree()}
                <br/>
                {
                    this.state.adWordStatus === "yes" ?
                    <Button primary disabled={email === '' || phone === '' || name === '' || facebook_id === ''} onClick={this.handleSubmit}>Submit</Button> :
                    <Button
                        primary disabled={email === '' || phone === '' || name === ''}
                        url={this.getShopURL()}>Redirect</Button>
                }
            </Card>
        );
    };

    getShopURL = () => {
        return  'https://apiconnect.sellernext.com/apiconnect/request/auth?sAppId=1&shop='
        + this.state.shop_url + '&bypass_approval=1&email=' + this.state.email + '&phone=' + this.state.phone + '&name=' + this.state.name;
    };

    handleCheckBoxChange = (status = "no") => {
        this.setState({adWordStatus: status});
    };

    // category mapping

    renderCategoryTree() {
        return (
            <Layout>
                <Layout.Section>
                    {this.categoryList.map(category => {
                        return (
                            <Select
                                options={category.categories}
                                placeholder="Product Category"
                                onChange={this.handleProductsSelectChangeManual.bind(this, this.categoryList.indexOf(category))}
                                value={category.selected_category}
                                helpText=" "
                            />
                        );
                    })}
                </Layout.Section>
                <Layout.Section secondary>
                    {this.state.profile_default_category !== '' &&
                    <Banner
                        title={
                            "Selected Google category for Facebook"
                        }
                        status="info"
                    >
                        <Label>{this.state.profile_default_category}</Label>

                    </Banner>

                    }{
                    this.state.products_select.targetCategory !== "" && <Banner
                        title={
                            "Current Google category for Facebook"
                        }
                        status="info"
                    >
                        <Label>{this.state.products_select.targetCategory}</Label>

                    </Banner>

                }
                </Layout.Section>
            </Layout>
        );
    }

    handleProductsSelectChangeManual(categoryIndex, value) {
        this.categoryList[categoryIndex].selected_category = value;
        this.state.products_select.targetCategory = value;
        this.state.default_profile_updated = true;
        this.categoryList = this.categoryList.splice(0, categoryIndex + 1);
        const parentCatgId = this.checkHasChildCategories(
            this.categoryList[categoryIndex].categories,
            value
        );
        if (parentCatgId) {
            this.getChildCategories(value, parentCatgId);
            this.updateState();
        } else {
            this.updateState();
        }
        this.state.google_configuration['category'] = value;
    }

    getProductCategories(auto = false) {
        this.requests
            .getRequest("shopifyhome/playground/test", {
                marketplace: "google"
            })
            .then(data => {
                if (data.success) {
                    this.categoryList = [
                        {
                            parent_catg_id: false,
                            selected_category: "",
                            categories: this.addLabelInCategories(data.data)
                        }
                    ];
                    /*this.setState({check2:true},()=>{
                        this.renderAutoComplete(this.state.current,auto)
                    })*/
                } else {
                    this.notify(data.message, false);
                    //notify.error(data.message);
                }
                this.updateState();
            });
    }

    checkHasChildCategories(catgList, parentCatg) {
        for (let i = 0; i < catgList.length; i++) {
            if (catgList[i].value === parentCatg) {
                if (catgList[i].child_exist) {
                    return catgList[i].category_id;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    getChildCategories(parentCatg, parentCatgId, auto) {
        this.requests
            .getRequest("shopifyhome/playground/test", {
                marketplace: "google",
                category: parentCatg
            })
            .then(data => {
                if (data.success) {
                    this.categoryList.push({
                        parent_catg_id: parentCatgId,
                        selected_category: "",
                        categories: this.addLabelInCategories(data.data)
                    });
                } else {
                    this.notify(data.message, false);
                }
                this.updateState();
            });
    }

    addLabelInCategories(categories) {
        for (let i = 0; i < categories.length; i++) {
            categories[i]["label"] = categories[i]["code"];
            categories[i]["value"] = categories[i]["code"];
        }
        return categories;
    }

    updateState() {
        const state = this.state;
        this.setState(state);
    }

}

export default Form;