import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { AppProvider, Frame, Toast, Loading } from "@shopify/polaris";

import translations from "@shopify/polaris/locales/en.json";
// import fixing from "./assets/img/fixing.svg";
import { globalState } from "./services";
import { Base } from "./core";
// import { environment } from "./environments/environment";
import OthersRoutes from "./components/other/routes";
import { connect } from "react-redux";
import { showNotification, hideNotification } from "./Actions";
// import { Route, Switch, Redirect, withRouter } from "react-router-dom";

const Panel = lazy(() => import("./components/panel/panel"));
const Auth = lazy(() => import("./components/auth/auth"));

class App extends Base {
  state = {
    showLoader: false,
    shopOrigin: "",
    hide_offer: true,
    ced_token_expire: false,
    toastIds: [1]
  };

  constructor(props) {
    super(props);

    setTimeout(() => {
      this.getShopOrigin();
    }, 400);
  }

  getShopOrigin() {
    if (
      globalState.getLocalStorage("shop") !== null &&
      globalState.getLocalStorage("shop") !== undefined
    ) {
      if (!this.inFrame()) {
        this.state.shopOrigin = globalState.getLocalStorage("shop");
        globalState.removeLocalStorage("shop");
        this.setState(this.state);
      }
    }
  }

  checkTheValidation = (status) => {
    this.setState({ ced_token_expire: status });
  };

  inFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  renderApp() {
    let { ced_token_expire } = this.state;
    return (
      <Frame>
        {this.renderToast()}
        {ced_token_expire ? (
          <>fragment</>
        ) : (
          <Switch>
            <Route
              path='/auth'
              render={() => (
                <Suspense fallback={<Loading />}>
                  <Auth />
                </Suspense>
              )}
            />
            <Route
              path='/panel'
              render={() => {
                return (
                  <Suspense fallback={<Loading />}>
                    <Panel checkTheValidation={this.checkTheValidation} />
                  </Suspense>
                );
              }}
            />
            <Route
              path='/show'
              render={() => {
                return <Route path='/show' component={OthersRoutes} />;
              }}
            />
            <Redirect path='**' to='/auth' />
          </Switch>
        )}
      </Frame>
    );
  }

  renderToast = () => {
    return Object.keys(this.props.utility.showToast).map((e) => (
      <Toast
        key={e}
        className={"textdd"}
        content={this.props.utility.showToast[e]["message"]}
        error={this.props.utility.showToast[e]["error"]}
        duration={3000}
        onDismiss={this.props.hideNotification.bind(this, e)}
      />
    ));
  };

  render() {
    let { theme } = this.props.necessaryInfo;
    if (this.state.shopOrigin !== "") {
      return (
        <AppProvider
          i18n={translations}
          // apiKey={environment.APP_API_KEY}
          // shopOrigin={this.state.shopOrigin}
          // forceRedirect={true}
          features={{ newDesignLanguage: theme.newDesign }}
          theme={{
            colorScheme: theme.type,
            colors: {
              surface: "#111213",
              onSurface: "#111213",
              interactive: "#2e72d2",
              secondary: "#111213",
              primary: "#3b5998",
              critical: "#d82c0d",
              warning: "#ffc453",
              highlight: "#5bcdda",
              success: "#008060",
              decorative: "#ffc96b"
            }
          }}
        >
          {this.renderApp()}
        </AppProvider>
      );
    } else {
      return (
        <AppProvider
          i18n={translations}
          features={{ newDesignLanguage: theme.newDesign }}
          theme={{
            colorScheme: theme.type,
            colors: {
              surface: "#111213",
              onSurface: "#111213",
              interactive: "#2e72d2",
              secondary: "#111213",
              primary: "#3b5998",
              critical: "#d82c0d",
              warning: "#ffc453",
              highlight: "#5bcdda",
              success: "#008060",
              decorative: "#ffc96b"
            }
          }}
        >
          {this.renderApp()}
        </AppProvider>
      );
    }
  }
}

/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

/*const OnboardingScreenComponent = () => (
  <svg width={1366} height={768} viewBox="0 0 1366 768">
    <defs>
      <style>
        {
          ".a{clip-path:url(#b);}.b{fill:#ecebff;}.c,.h,.i,.j,.m,.n{fill:#0a0a0a;}.c{opacity:0.12;}.d{fill:#fff;}.e{fill:#444;font-size:16px;opacity:0.9;}.e,.j,.s{font-family:Montserrat-Medium, Montserrat;font-weight:500;}.f,.i,.q{font-family:Montserrat-SemiBold, Montserrat;font-weight:600;}.g{fill:#413bbc;}.h{font-size:32px;font-family:Montserrat-ExtraBold, Montserrat;font-weight:800;}.h,.j{opacity:0.87;}.i,.j{font-size:24px;}.i{opacity:0.8;}.k,.s{fill:#707070;}.l{fill:#66ed78;}.n{opacity:0.2;}.o,.r{fill:none;}.o{stroke:#707070;stroke-width:0.5px;}.o,.p{opacity:0.3;}.p{fill:#272372;}.q{fill:#fafafa;font-size:20px;letter-spacing:0.05em;}.r{stroke:#fafafa;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.s{font-size:14px;}.t{font-family:Montserrat-Bold, Montserrat;font-weight:700;}"
        }
      </style>
      <clipPath id="b">
        <rect width={1366} height={768} />
      </clipPath>
    </defs>
    <g id="a" className="a">
      <rect className="d" width={1366} height={768} />
      <rect className="b" width={1366} height={768} />
      <rect
        className="c"
        width={1031}
        height={538}
        rx={20}
        transform="translate(168 143)"
      />
      <rect
        className="d"
        width={1088}
        height={534}
        rx={20}
        transform="translate(139 117)"
      />
      <g transform="translate(-4 -11)">
        <g transform="translate(255 -4)">
          <text className="e" transform="translate(442 584)">
            <tspan x={0} y={15}>
              {"By clicking "}
            </tspan>
            <tspan className="f" y={15}>
              {"Connect"}
            </tspan>
            <tspan y={15}>{", you agree to all"}</tspan>
            <tspan className="g" y={15} xmlSpace="preserve">
              {" terms & conditions."}
            </tspan>
          </text>
        </g>
        <g transform="translate(-1)">
          <text className="h" transform="translate(222 301)">
            <tspan x={0} y={0}>
              {"Welcome!"}
            </tspan>
          </text>
          <text className="i" transform="translate(222 342)">
            <tspan x={0} y={0}>
              {"Please link your facebook account"}
            </tspan>
          </text>
          <text className="j" transform="translate(222 210)">
            <tspan x={0} y={0} xmlSpace="preserve">
              {"STEP  1"}
            </tspan>
            <tspan className="k" y={0} xmlSpace="preserve">
              {" / 4"}
            </tspan>
          </text>
          <g transform="translate(409 -125)">
            <circle
              className="l"
              cx={8.5}
              cy={8.5}
              r={8.5}
              transform="translate(635 318)"
            />
            <circle
              className="m"
              cx={8.5}
              cy={8.5}
              r={8.5}
              transform="translate(635 318)"
            />
            <circle
              className="n"
              cx={8.5}
              cy={8.5}
              r={8.5}
              transform="translate(666 318)"
            />
            <circle
              className="n"
              cx={8.5}
              cy={8.5}
              r={8.5}
              transform="translate(697 318)"
            />
            <circle
              className="n"
              cx={8.5}
              cy={8.5}
              r={8.5}
              transform="translate(728 318)"
            />
          </g>
          <line className="o" x1={930.93} transform="translate(222.5 243.5)" />
        </g>
        <g transform="translate(182 -10)">
          <rect
            className="p"
            width={255}
            height={63}
            rx={20}
            transform="translate(700 507)"
          />
          <rect
            className="g"
            width={288}
            height={60}
            rx={20}
            transform="translate(683 497)"
          />
          <g
            transform="translate(9.5 -4)"
            onClick={() => {
              alert("ddd");
            }}
            style={{ cursor: "pointer" }}
          >
            <text className="q" transform="translate(776 538)">
              <tspan x={0} y={0}>
                {"CONNECT"}
              </tspan>
            </text>
            <path
              className="r"
              d="M18,2H15a5,5,0,0,0-5,5v3H7v4h3v8h4V14h3l1-4H14V7a1,1,0,0,1,1-1h3Z"
              transform="translate(743.5 520.5)"
            />
          </g>
        </g>
      </g>
      <text className="s" transform="translate(526 708)">
        <tspan x={3.511} y={14}>
          {"Copyright. A product by"}
        </tspan>
        <tspan className="t" y={14} xmlSpace="preserve">
          {"entredgeInc"}
        </tspan>
      </text>
    </g>
  </svg>
);*/

const mappedStateToProps = (state) => ({
  utility: state.utility,
  necessaryInfo: state.necessaryInfo
});

export default connect(mappedStateToProps, {
  hideNotification,
  showNotification
})(withRouter(App));
