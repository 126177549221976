import React, { Component } from "react";
import * as queryString from "query-string";
import { Spinner, Heading } from "@shopify/polaris";
import "./message.css";
import { faCheck, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@shopify/polaris";
import { globalState } from "../../services/globalstate";
import { environment } from "../../environments/environment";

class MessageShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      title: "",
      success: "",
      shop: null,
      failed: "",
      isPlan: true,
      icon: faCheck
    };
    globalState.removeLocalStorage("shop");
  }
  componentWillMount() {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.message != null && queryParams.success != null) {
      if (
        queryParams.success === undefined ||
        queryParams.success === "false"
      ) {
        queryParams.success = "BG-danger";
      } else {
        queryParams.success = "BG-success";
      }
      if (
        queryParams.icon !== null &&
        typeof queryParams.icon !== "undefined"
      ) {
        this.setState({ icon: faToolbox });
        queryParams.success = "BG-info";
      }
      this.setState({
        message: queryParams.message,
        title: queryParams.title,
        success: queryParams.success,
        shop: queryParams.shop !== undefined ? queryParams.shop : null,
        isPlan: queryParams.isPlan !== undefined
      });
      globalState.setLocalStorage(
        "connection_status",
        JSON.stringify({
          ...queryParams
        })
      );
      let win = window.open(
        "",
        "_parent",
        "location=yes,width=20px,height=10px,scrollbars=yes,status=yes"
      );
      setTimeout(() => {
        if (queryParams.shop !== undefined && environment.isLive) {
          globalState.setLocalStorage("shop", queryParams.shop);
          // win.location = "https://" + queryParams.shop + "/admin/apps/" + environment.appURL;
          win.location =
            "https://apiconnect.sellernext.com/apiconnect/request/auth?sAppId=1&shop=" +
            queryParams.shop;
        } else {
          this.redirect("/panel/dashboard");
        }
      }, 500);
    } else {
      let win = window.open(
        "",
        "_parent",
        "location=yes,width=200px,height=500px,scrollbars=yes,status=yes"
      );
      setTimeout(() => {
        if (queryParams.shop !== undefined && environment.isLive) {
          globalState.setLocalStorage("shop", queryParams.shop);
          // win.location = "https://" + queryParams.shop + "/admin/apps/" + environment.appURL;
          win.location =
            "https://apiconnect.sellernext.com/apiconnect/request/auth?sAppId=1&shop=" +
            queryParams.shop;
        } else {
          this.redirect("/panel/dashboard");
        }
      }, 3000);
    }
  }
  redirect(url) {
    // this.props.history.push(url);
  }
  handle = () => {
    // window.open(
    // 	"https://apiconnect.sellernext.com/apiconnect/request/auth?sAppId=1&shop=" + this.state.shop,
    // 	"_parent"
    // );
  };
  render() {
    return (
      <div className='row text-center m-5 h-100'>
        {!this.state.isPlan ? (
          <div className='offset-md-3 offset-sm-1 col-md-6 col-sm-10 col-12 mt-5'>
            <div className='CARD w-100'>
              {/*<div*/}
              {/*className={`CARD-title-small text-center ${this.state.success}`}*/}
              {/*>*/}
              {/*{this.state.success === "BG-success" ? (*/}
              {/*<FontAwesomeIcon icon={this.state.icon} size="5x" />*/}
              {/*) : this.state.success === "BG-info" ? (*/}
              {/*<FontAwesomeIcon icon={this.state.icon} size="5x" />*/}
              {/*) : (*/}
              {/*<FontAwesomeIcon icon={faTimes} size="5x" />*/}
              {/*)}*/}
              {/*</div>*/}
              <div
                className='CARD-body col-12 p-2 w-100'
                style={{ height: 300 }}
              >
               
                <Heading>
                  <p dangerouslySetInnerHTML={{ __html: this.state.message }} />
                </Heading>
              
               
                <img
                  src='https://cdn.shopify.com/s/files/1/2376/3301/products/file-upload-empty-state.png'
                  alt={""}
                />
              </div>
              {this.state.shop !== null ? (
                <div className=' col-12 text-center'>
                  <Button
                    onClick={() => {
                      window.open("https://admin.ozdingo.com.au/");
                    }}
                  >
                    Home
                  </Button>
                  <p style={{ color: "#645f5b" }}>
                    *In Case redirect not happen,
                    <br /> Click on Home Button
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className='col-12 d-flex justify-content-center'>
              <div className='row'>
                {this.state.message !== "" && (
                  <div className='col-12 text-center'>
                 
                    <Heading>{this.state.message}</Heading>
                 
                  </div>
                )}
                <div className='col-12 text-center'>
                  <Spinner />
                </div>
                <div className='col-12 text-center'>
                  <h4>Please Wait..</h4>
                </div>
              </div>
            </div>
            {this.state.shop !== null ? (
              <div className=' col-12 text-center'>
                <Button
                  onClick={() => {
                    window.open("https://admin.ozdingo.com.au/");
                  }}
                >
                  Home
                </Button>
                <p style={{ color: "#645f5b" }}>
                  *In Case redirect not happen,
                  <br /> Click on Home Button
                </p>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default MessageShow;
